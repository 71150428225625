import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const DetailsRole = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const goToList = () => {
    navigate("/Role");
  };

  return (
    <div className="container-fluid">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3 className="mb-3">Details</h3>
          <h5>Detail Role</h5>
        </div>
        <div>
          <button className="pds-button pds-button-primary mt-3" onClick={goToList}>
            Back to List
          </button>
        </div>
      </div>
      <table style={{ width: "50%", margin: "1% 0 0.5% 3%" }} className="table">
        <tr>
          <th>Role Id</th>
          <td>{location.state.id}</td>
        </tr>
        <tr>
          <th>System/Role</th>
          <td>{location.state.role}</td>
        </tr>
        <tr>
          <th>AD Group</th>
          <td>{location.state.adGroup}</td>
        </tr>
        <tr>
          <th>Data Subject</th>
          <td>{location.state.dataSubject}</td>
        </tr>
        <tr>
          <th>Error Category</th>
          <td>{location.state.categoryId}</td>
        </tr>
      </table>
    </div>
  );
};

export default DetailsRole;
