import jsPDF from "jspdf";
import PropTypes from "prop-types";

const ExportToPdf = ({ data, type = null }) => {
  const heading =
    type?.toLowerCase() === "audit" ? "Audit - PGI Error Dashboard" : "Error Messages - PGI Error Dashboard";

  const exportToPdf = () => {
    var doc = new jsPDF("p", "pt", "letter");
    var y = 10;
    doc.setLineWidth(2);
    doc.text(heading, doc.internal.pageSize.getWidth() / 2, (y = y + 30), { align: "center" });
    const tableBody = getTableBody(type);
    doc.autoTable({
      body: tableBody,
      startY: 70,
      styles: {
        fontSize: 6,
      },
      theme: "striped",
      columnStyles: getColumnStyles(type),
      columns: getColumns(type),
    });
    doc.save(heading);
  };

  const getTableBody = (type) => {
    let errorData = [];
    const newData = data.map((item) => [
      item.insertedDate,
      item.errorDate,
      item.systemId,
      item.dataSubject,
      item.categoryId,
      item.error,
      item.field1,
      item.field2,
      item.field3,
      item.field4,
      item.action,
    ]);
    if (type.toLowerCase() !== "audit") {
      errorData = newData.filter((item) => item.shift());
      return errorData;
    }
    return newData;
  };

  const getColumns = (type) => {
    let columns = [];
    columns = [
      {
        header: "Inserted Date",
        dataKey: "insertedDate",
      },
      {
        header: "Date",
        dataKey: "errorDate",
      },
      {
        header: "System",
        dataKey: "systemId",
      },
      {
        header: "Data Subject",
        dataKey: "dataSubject",
      },
      {
        header: "Category",
        dataKey: "categoryId",
      },
      {
        header: "Error",
        dataKey: "error",
      },
      {
        header: "Field 1",
        dataKey: "field1",
      },
      {
        header: "Field 2",
        dataKey: "field2",
      },
      {
        header: "Field 3",
        dataKey: "field3",
      },
      {
        header: "Field 4",
        dataKey: "field3",
      },
      {
        header: "Status",
        dataKey: "action",
      },
    ];

    if (type.toLowerCase() !== "audit") {
      const newColumns = columns.filter((item) => item.dataKey !== "insertedDate");
      columns = newColumns;
    }
    return columns;
  };

  const getColumnStyles = (type) => {
    if (type.toLowerCase() === "audit") {
      return {
        1: {
          halign: "left",
          cellWidth: 50,
        },
        2: {
          halign: "left",
          cellWidth: 50,
        },
        3: {
          halign: "left",
          cellWidth: 40,
        },
        4: {
          halign: "left",
          cellWidth: 40,
        },
        5: {
          halign: "left",
          cellWidth: 100,
        },
        6: {
          halign: "left",
          cellWidth: 40,
        },
        7: {
          halign: "left",
          cellWidth: 40,
        },
        8: {
          halign: "left",
          cellWidth: 40,
        },
        9: {
          halign: "left",
          cellWidth: 40,
        },
        10: {
          halign: "left",
          cellWidth: 40,
        },
        11: {
          halign: "left",
          cellWidth: 30,
        },
      };
    } else {
      return {
        1: {
          halign: "left",
          cellWidth: 50,
        },
        2: {
          halign: "left",
          cellWidth: 50,
        },
        3: {
          halign: "left",
          cellWidth: 50,
        },
        4: {
          halign: "left",
          cellWidth: 100,
        },
        5: {
          halign: "left",
          cellWidth: 50,
        },
        6: {
          halign: "left",
          cellWidth: 50,
        },
        7: {
          halign: "left",
          cellWidth: 50,
        },
        8: {
          halign: "left",
          cellWidth: 50,
        },
        9: {
          halign: "left",
          cellWidth: 50,
        },
        10: {
          halign: "left",
          cellWidth: 30,
        },
      };
    }
  };

  return (
    <>
      <button className="pds-button pds-button-secondary mr-2" onClick={exportToPdf}>
        PDF
      </button>
    </>
  );
};

export default ExportToPdf;

ExportToPdf.propTypes = {
  data: PropTypes.any,
  type: PropTypes.any,
};
