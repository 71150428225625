import PropTypes from "prop-types";

const ExportToCsv = ({ ...props }) => {
  const type = props.type?.toLowerCase();
  // start code for excel pdf copy
  const downloadFile = ({ datas, fileName, fileType }) => {
    const blob = new Blob([datas], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = () => {
    let tableData = set_nulls_to_empty_strings(props.data);
    let headers = [
      // "Date,Id,PK,SK,UserName,System,DataSubject,Category,Error,Field1,Field2,Field3,Field4,Action,Tags,TTL,Arn,CloudRole,Comment,ErrorDate,FieldDisplayName,FieldValueType,FieldValue,Component,DestinationAddress,Exception,ExceptionType,InnerException,MessageId,MessageSubject,PayloadType,RawData,ResubmitQueue",
      "Date,Id,PK,SK,System,DataSubject,Category,Error,Field1,Field2,Field3,Field4,Action,Tags,ErrorDate,FieldValue,MessageId",
    ];

    let usersCsv = tableData.reduce((acc, user) => {
      let {
        insertedDate,
        id,
        PK,
        SK,
        // userName,
        systemId,
        dataSubject,
        categoryId,
        error,
        field1,
        field2,
        field3,
        field4,
        action,
        tag,
        // ttl,
        // arn,
        // cloudRole,
        // comment,
        errorDate,
        // fieldDisplayName,
        // fieldValueType,
        fieldValue,
        // component,
        // destinationAddress,
        // exception,
        // exceptionType,
        // innerException,
        messageId,
        // messageSubject,
        // payloadType,
        // rawData,
        // resubmitQueue,
      } = user;

      // fieldDisplayName = mapField(fieldDisplayName, "fieldDisplayName");
      // fieldValueType = mapField(fieldValueType, "fieldValueType");
      fieldValue = mapField(fieldValue, "fieldValue");
      // exception = mapField(exception, "exception");
      // exceptionType = mapField(exceptionType, "exceptionType");
      // resubmitQueue = mapField(resubmitQueue, "resubmitQueue");
      // rawData = mapField(rawData, "rawData");

      acc.push(
        [
          insertedDate,
          id,
          PK,
          SK,
          // userName,
          systemId,
          dataSubject,
          categoryId,
          error,
          field1,
          field2,
          field3,
          field4,
          action,
          tag,
          // ttl,
          // arn,
          // cloudRole,
          // comment,
          errorDate,
          // fieldDisplayName,
          // fieldValueType,
          fieldValue,
          // component,
          // destinationAddress,
          // exception,
          // exceptionType,
          // innerException,
          messageId,
          // messageSubject,
          // payloadType,
          // rawData,
          // resubmitQueue,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      datas: [...headers, ...usersCsv].join("\n"),
      fileName: type === "audit" ? "Audit - PGI Error Dashboard.csv" : "Error Messages - PGI Error Dashboard.csv",
      fileType: "text/csv",
    });
  };

  const set_nulls_to_empty_strings = (apiResults) => {
    if (Array.isArray(apiResults)) {
      let res = [];
      for (const index in apiResults) {
        res.push(set_nulls_to_empty_strings(apiResults[index]));
      }
      return res;
    } else {
      for (const key in apiResults) {
        if (apiResults[key] === null) {
          apiResults[key] = "";
        }
      }
      return apiResults;
    }
  };

  const mapField = (data, field) => {
    if (field === "fieldDisplayName" || field === "fieldValueType" || field === "fieldValue") {
      const mappedField = data?.map((item) => item).join("', '");
      return "\"'" + mappedField + "'\"";
    } else if (field === "resubmitQueue" || field === "rawData") {
      return '"' + data.replaceAll('"', "'") + '"';
    } else {
      return '"' + data + '"';
    }
  };

  return (
    <>
      <button className="pds-button pds-button-secondary mr-2" onClick={exportToCsv}>
        CSV
      </button>
    </>
  );
};

ExportToCsv.propTypes = {
  data: PropTypes.string,
  type: PropTypes.string,
};

export default ExportToCsv;
