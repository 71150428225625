import sbx from "./environments/sbx";
import nonprod from "./environments/nonprod";
import prod from "./environments/prod";
import local from "./environments/local";
import sbx_dr from "./environments/sbx_dr";
import nonprod_dr from "./environments/nonprod_dr";
import prod_dr from "./environments/prod_dr";

const config =
  process.env.REACT_APP_ENV === "prod"
    ? prod
    : process.env.REACT_APP_ENV === "nonprod_dr"
      ? nonprod_dr
      : process.env.REACT_APP_ENV === "nonprod"
        ? nonprod
        : process.env.REACT_APP_ENV === "sbx"
          ? sbx
          : process.env.REACT_APP_ENV === "nonprod_dr"
            ? nonprod_dr
            : process.env.REACT_APP_ENV === "sbx_dr"
              ? sbx_dr
              : process.env.REACT_APP_ENV === "prod_dr"
                ? prod_dr
                : local;

export default config;
