import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@pds-react/icon";
import "../Message/BasicTable.css";
import "../../App.css";
import "./Role.css";
import "jspdf-autotable";
import { fetchAdGroups } from "../../graph";
import { useMsal } from "@azure/msal-react";
import { AppContext } from "../../Context/context";
import Config from "../../config/index";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";
import jsPDF from "jspdf";
import Loading from "../Loading/Loading";

const Role = () => {
  const { instance, accounts } = useMsal();
  const email = accounts[0] && accounts[0].idTokenClaims.email;
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setApiData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = React.useState({});
  const [sorting, setSorting] = React.useState([]);
  const [roleLoad, setRoleLoad] = useState(0);

  const referenceData = useContext(AppContext);
  const { categoryList, dataDomainAccessToken, requestDataDomainAccessToken, subjectList, tokenExpiry } = referenceData;
  const token = dataDomainAccessToken;
  const tokenRefresh = requestDataDomainAccessToken;
  const tokenExpiryDate = new Date(tokenExpiry);

  const RequestGroupData = async () => {
    const response = await instance.acquireTokenSilent({
      ...Config.graphApiScopeRequest,
      account: accounts[0],
    });
    const res = await fetchAdGroups(response.accessToken, email);
    return res;
  };

  const admin_check = async () => {
    const graphGroupData = await RequestGroupData();
    let graphData = graphGroupData?.value.map((gd) => gd.onPremisesSamAccountName);
    let adminFlag = false;
    for (let i in data) {
      let eachRecord = data[i];
      let group = eachRecord["adGroup"];
      let role = eachRecord["role"];
      if (graphData.includes(group)) {
        if (role.replace(" - View", "").trim().toLowerCase() === "admin") {
          adminFlag = true;
          break;
        }
      }
    }
    setIsAdmin(adminFlag);
  };

  const updateData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        dataSubject: getLabelName(item.dataSubject, subjectList),
        categoryId: getLabelName(item.categoryId, categoryList),
      };
    });
  };

  const getLabelName = (itemId, valueMapped) => {
    if (valueMapped) {
      const findItem = valueMapped.find((i) => Number(i.Id) === Number(itemId));
      const findVal = findItem ? findItem.Name : itemId;
      return findVal;
    } else {
      return itemId;
    }
  };

  const getRoleData = async () => {
    if (tokenExpiryDate < new Date()) {
      await tokenRefresh();
      return;
    }
    try {
      const response = await fetch(`${Config.pedrApiUrl.ROLE_API_URL}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (response.status !== 200) {
        throw new Error(res.requestId);
      }
      const mappedData = updateData(res.data);
      const sortedMappedData = mappedData.sort((a, b) => b.role.localeCompare(a.role));
      setApiData(sortedMappedData);
    } catch (e) {
      console.log(e);
      navigate("/error", { state: { e } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await admin_check();
    })();
  }, [data]);

  useEffect(() => {
    getRoleData().catch((e) => console.log(e));
  }, [roleLoad]);

  useEffect(() => {
    setRoleLoad((c) => c + 1);
  }, [categoryList, dataDomainAccessToken]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ["Role Id,Role,AD Group, Data Subject, Category, Action,"];

    let usersCsv = data.reduce((acc, user) => {
      const { id, role, adGroup, dataSubject, categoryId, action } = user;
      acc.push([id, role, adGroup, dataSubject, categoryId, action].join(","));
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Roles - PGI Error Dashboard.csv",
      fileType: "text/csv",
    });
  };

  const exportToJson = () => {
    const elTable = document.querySelector("#product-table");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 2000);
  };

  const exportToPdf = () => {
    var doc = new jsPDF("p", "pt", "letter");
    var y = 20;
    doc.setLineWidth(2);
    doc.text(250, (y = y + 30), "Role Data");
    doc.autoTable({
      html: "#product-table",
      startY: 70,
      theme: "striped",

      columnStyles: {
        1: {
          halign: "left",
          cellWidth: 100,
        },
        2: {
          halign: "left",
          cellWidth: 100,
        },
        3: {
          halign: "left",
          cellWidth: 100,
        },
        4: {
          halign: "left",
          cellWidth: 70,
        },
        5: {
          halign: "left",
          cellWidth: 70,
        },
        6: {
          halign: "left",
          cellWidth: 120,
        },
      },
    });
    doc.save("Roles - PGI Error Dashboard");
  };

  //end code copy pdf
  const columns = React.useMemo(() => [
    {
      accessorKey: "id",
      header: () => <span>Role Id</span>,
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "role",
      header: () => <span>Role</span>,
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "adGroup",
      header: () => <span>AD Group</span>,
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "dataSubject",
      header: () => <span>Data Subject</span>,
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "categoryId",
      header: () => <span>Category</span>,
      footer: (props) => props.column.id,
    },
  ]);

  if (isAdmin) {
    columns.push({
      accessorKey: "",
      header: "Action",
      footer: (props) => props.column.id,
      cell: ({ row }) => {
        return (
          <div className="action-data">
            <span
              onClick={() => {
                editData(row);
              }}
            >
              Edit |
            </span>
            <span
              onClick={() => {
                detailsData(row);
              }}
            >
              Details |
            </span>
            <span
              onClick={() => {
                deleteData(row);
              }}
            >
              Delete
            </span>
          </div>
        );
      },
    });
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      expanded,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    debugTable: true,
  });

  const getPayload = (data) => {
    return {
      PK: data.PK,
      SK: data.SK,
      id: data.id,
      adGroup: data.adGroup,
      role: data.role,
      dataSubject: data.dataSubject,
      categoryId: data.categoryId,
    };
  };

  let navigate = useNavigate();
  const createData = () => {
    navigate("/CreateRole");
  };
  const editData = (row) => {
    navigate("/EditRole", {
      state: getPayload(row.original),
    });
  };
  const detailsData = (row) => {
    navigate("/DetailsRole", {
      state: getPayload(row.original),
    });
  };
  const deleteData = (row) => {
    navigate("/DeleteRole", {
      state: getPayload(row.original),
    });
  };

  return (
    <div className="container-fluid">
      {isAdmin && (
        <button
          className="pds-button pds-button-primary mb-3"
          style={{ left: "1px", position: "relative" }}
          onClick={createData}
        >
          Create
        </button>
      )}
      {!isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div className="">
              <div className="pds-select pds-dataTable-select-options" style={{ display: "flex" }}>
                <div
                  style={{
                    marginTop: "15px",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    margin: "5px",
                    marginBottom: "20px",
                  }}
                >
                  Show Entries
                </div>
                <select
                  style={{
                    width: "36%",
                    height: "30px",
                    paddingLeft: "5px",
                    border: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                <span className="down-arrow">
                  <Icon name="chevron-down" size={24} />
                </span>
              </div>
            </div>
            <div>
              <button className="pds-button pds-button-secondary mr-2" onClick={() => table.resetColumnFilters()}>
                Clear Filters
              </button>
              <button onClick={exportToJson} className="pds-button pds-button-secondary mr-2">
                COPY
              </button>
              {isAlertVisible && (
                <div className="alert-container">
                  <div className="alert-inner">
                    <h3>Copy to Clipboard! Paste it in a file</h3>
                  </div>
                </div>
              )}
              <button onClick={exportToCsv} className="pds-button pds-button-secondary mr-2">
                CSV
              </button>

              <button onClick={exportToPdf} className="pds-button pds-button-secondary mr-2">
                PDF
              </button>
            </div>
          </div>
          <div>
            <div id="render_me">
              <table
                className="pds-table-container pds-table pds-table-bordered pds-table-striped-odd"
                id="product-table"
                style={{ tableLayout: "fixed" }}
              >
                <thead
                  className="pds-table pds-table-bordered"
                  style={{
                    backgroundColor: "#e6e6e6",
                    color: "black",
                    tableLayout: "fixed",
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            className="sort pds-table pds-table-bordered"
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: "25%" }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort() ? "cursor-pointer select-none mb-1" : "",
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: " 🔼",
                                  desc: " 🔽",
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                            {header.column.getCanFilter() ? (
                              <div>
                                <input
                                  type="text"
                                  value={header.column.getFilterValue() ?? ""}
                                  onChange={(e) => header.column.setFilterValue(e.target.value)}
                                  placeholder={`Search...`}
                                  className="w-36 border shadow rounded pds-input-small"
                                />
                              </div>
                            ) : null}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {(table.getRowModel().rows.length > 0 &&
                    table.getRowModel().rows.map((row) => {
                      return (
                        <>
                          <tr
                            key={row.id}
                            onClick={() => {
                              row.toggleExpanded();
                            }}
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  className="pds-dataTable-table-item"
                                  key={cell.id}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })) || (
                    <tr>
                      <td
                        className="pds-dataTable-table-item"
                        colSpan="10"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        No Records Found!
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* <tfoot>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <td key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div>
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={header.column.getFilterValue() ?? ""}
                                      onChange={(e) => header.column.setFilterValue(e.target.value)}
                                      placeholder={`Search...`}
                                      className="w-36 border shadow rounded pds-input-small"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tfoot> */}
              </table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
              >
                <nav className="pds-pagination">
                  <ul>
                    <li className="pds-pagination-item">
                      <button
                        className={"pds-pagination-link pds-pagination-fly-first"}
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<<"}
                      </button>
                    </li>
                    <li className="pds-pagination-item">
                      <button
                        className={"pds-pagination-link pds-pagination-step-forward"}
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<"}
                      </button>
                    </li>
                    <li className="pds-pagination-item">
                      <span className="flex items-center gap-1 pds-pagination-link">
                        <div>Page</div>
                        <strong>
                          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                        </strong>
                      </span>
                    </li>
                    <li className="pds-pagination-item">
                      <span className="flex items-center gap-1 pds-pagination-link">
                        Go to page:
                        <input
                          type="number"
                          defaultValue={table.getState().pagination.pageIndex + 1}
                          onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            table.setPageIndex(page);
                          }}
                          style={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            marginLeft: "5px",
                            height: "30px",
                            width: "48px",
                            padding: "5px",
                          }}
                        />
                      </span>
                    </li>
                    <li className="pds-pagination-item">
                      <button
                        className={"pds-pagination-link pds-pagination-step-backward"}
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        {">"}
                      </button>
                    </li>
                    <li className="pds-pagination-item">
                      <button
                        className={"pds-pagination-link pds-pagination-fly-last"}
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                      >
                        {">>"}
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Role;

Role.propTypes = {
  column: PropTypes.any,
};
