import React, { useState, useEffect, useContext } from "react";
import "./EditRole.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../Context/context";
import Modal from "react-modal";
import Config from "../../config/index";
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "56px 44px 48px 32px",
    width: "468px",
    textAlign: "center",
  },
};
const EditRole = () => {
  const [adGroupData, setAdGroupData] = useState("");
  const [dataSubjectData, setDataSubjectData] = useState("");
  const [roleData, setRoleData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [existingDataSubject, setExistingDataSubject] = useState("");
  const [existingRole, setExistingRole] = useState("");
  const [existingCategoryId, setExistingCategoryId] = useState("");
  const [roleEdit, setRoleEdit] = useState({});
  const [roleValidation, setRoleValidation] = useState("");
  const [dataSubjectValidation, setDataSubjectValidation] = useState("");
  const [categoryValidation, setCategoryValidation] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const referenceData = useContext(AppContext);
  const systemList = referenceData.systemList;
  const subjectList = referenceData.subjectList;
  const categoryList = referenceData.categoryList;
  const token = referenceData.dataDomainAccessToken;

  useEffect(() => {
    loadRoleEditData(location.state);
  }, []);

  useEffect(() => {
    checkIfSame();
    checkIfIsValid();
  }, [roleData, dataSubjectData, categoryData]);

  let navigate = useNavigate();
  const goToList = () => {
    let path = "/Role";
    navigate(path);
  };

  const handleRoleChange = (e) => {
    setChangedValues(e.target.value, "role");
    checkIfIsValid();
    checkIfSame();
  };
  const handleDataSubjectChange = (e) => {
    setChangedValues(e.target.value, "dataSubject");
    checkIfIsValid();
    checkIfSame();
  };
  const handleCategoryChange = (e) => {
    setChangedValues(e.target.value, "category");
    checkIfIsValid();
    checkIfSame();
  };

  const sortedRoleList = systemList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedSubjectList = subjectList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedCategoryList = categoryList.sort((a, b) => a.Name.localeCompare(b.Name));

  const openEditRoleModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const getRefDataAsObject = (item) => {
    return {
      ...item,
      role: getRefObject(item.role, systemList, "role"),
      dataSubject: getRefObject(item.dataSubject, subjectList, "dataSubject"),
      categoryId: getRefObject(item.categoryId, categoryList, "categoryId"),
      existingRole: getRefObject(item.existingRole, systemList, "role"),
      existingDataSubject: getRefObject(item.existingDataSubject, subjectList, "dataSubject"),
      existingCategoryId: getRefObject(item.existingCategoryId, categoryList, "categoryId"),
    };
  };

  const getRefObject = (item, refData, type) => {
    if (refData) {
      let foundItem = {};
      let findVal = {};

      if (!type.includes("role")) {
        foundItem = refData.find((i) => Number(i.Id) === Number(item));
        findVal = foundItem ? { id: parseInt(foundItem.Id), name: foundItem.Name } : item;
      } else {
        foundItem = refData.find((i) => String(i.Name) === String(item.replace(" - View", "")));
        findVal = foundItem ? { id: parseInt(foundItem.Id), name: foundItem.Name } : item;
      }
      if (findVal == item) {
        foundItem = refData.find((i) => String(i.Name) === String(item.replace(" - View", "")));
        findVal = foundItem ? { id: parseInt(foundItem.Id), name: foundItem.Name } : item;
      }
      return findVal;
    } else {
      return item;
    }
  };

  const updatePayload = () => {
    const changedData = {
      ...roleEdit,
      role: roleData,
      dataSubject: dataSubjectData,
      categoryId: categoryData,
      existingRole: existingRole,
      existingDataSubject: existingDataSubject,
      existingCategoryId: existingCategoryId,
    };
    const result = getRefDataAsObject(changedData);
    return result;
  };

  const loadRoleEditData = (data) => {
    setRoleEdit(data);

    setRoleData(data.role);
    setAdGroupData(data.adGroup);
    setDataSubjectData(getRefId(data.dataSubject, "dataSubject"));
    setCategoryData(getRefId(data.categoryId, "category"));

    setExistingRole(data.role);
    setExistingDataSubject(data.dataSubject);
    setExistingCategoryId(data.categoryId);
  };

  const checkIfSame = () => {
    const ifSame =
      roleData == existingRole &&
      dataSubjectData == getRefId(existingDataSubject, "dataSubject") &&
      categoryData == getRefId(existingCategoryId, "category")
        ? true
        : false;
    setIsSame(ifSame);
  };

  const checkIfIsValid = () => {
    const ifValid = roleData != "" && dataSubjectData != "" && categoryData != "" ? true : false;
    setIsValid(ifValid);
  };

  const setChangedValues = (value, type) => {
    if (type == "role") {
      setRoleData(value);
    } else if (type == "dataSubject") {
      setDataSubjectData(value);
    } else {
      setCategoryData(value);
    }
  };

  const saveEditRole = async () => {
    validateData();
    try {
      if (isValid && !isSame) {
        const payload = updatePayload(roleEdit);
        const response = await fetch(`${Config.pedrApiUrl.ROLE_API_URL}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
        if (response.status !== 200) {
          throw new Error(`${response.status}`);
        }
        resetValidationState();
      }
    } catch (e) {
      navigate("/error", { state: { e } });
    }
  };

  const getRefId = (item, type) => {
    const refData = type == "dataSubject" ? sortedSubjectList : sortedCategoryList;
    const foundItem = refData.find((i) => String(i.Name) === String(item));
    const refId = foundItem ? foundItem.Id : item;
    return refId;
  };

  const validateData = () => {
    const roleValidationMsg = roleData == "" ? "The Role field is required." : "";
    setRoleValidation(roleValidationMsg);

    const dataSubjectValidationMsg = dataSubjectData == "" ? "The Data Subject field is required." : "";
    setDataSubjectValidation(dataSubjectValidationMsg);

    const categoryValidationMsg = categoryData == "" ? "The Error Category field is required." : "";
    setCategoryValidation(categoryValidationMsg);
  };

  const resetValidationState = () => {
    setIsValid(false);
    setRoleValidation("");
    setDataSubjectValidation("");
    setCategoryValidation("");
  };

  function closeModal() {
    setIsOpen(false);
    goToList();
  }

  async function updateRole() {
    setIsOpen(false);
    await saveEditRole();
    if (isValid && !isSame) {
      goToList();
    }
  }

  function cancelModal() {
    setIsOpen(false);
  }

  return (
    <div className="container-fluid">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3 className="mb-3">Edit</h3>
          <h5>Edit Role</h5>
        </div>
        <div>
          <button className="pds-button pds-button-primary mt-3" onClick={goToList}>
            Back to List
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <form style={{ width: "50%", margin: "0 auto" }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label htmlFor="adGroup" className="mb-3">
                AD Group
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <input type="text" id="adGroup" className="form-control" value={adGroupData} disabled={true} />
            </div>
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="role">
                System/Role
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select
                className="form-control"
                id="role"
                name="role"
                value={roleData}
                onChange={(e) => handleRoleChange(e)}
              >
                <option value="">Please select a role</option>
                {sortedRoleList.map((role) => (
                  <option key={role.Id} value={role.Name}>
                    {role.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {roleValidation}
              </p>
            )}
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="dataSubject">
                Data Subject
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select
                className="form-control"
                id="dataSubject"
                name="dataSubject"
                value={getRefId(dataSubjectData, "dataSubject")}
                onChange={(e) => handleDataSubjectChange(e)}
              >
                <option value="">Please select a data subject</option>
                {sortedSubjectList.map((subject) => (
                  <option key={subject.Id} value={subject.Id}>
                    {subject.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {dataSubjectValidation}
              </p>
            )}
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="category">
                Error Category
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select
                className="form-control"
                id="category"
                name="category"
                value={getRefId(categoryData, "category")}
                onChange={(e) => handleCategoryChange(e)}
              >
                <option value="">Please select a category</option>
                {sortedCategoryList.map((category) => (
                  <option key={category.Id} value={category.Id}>
                    {category.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {categoryValidation}
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                className="pds-button pds-button-primary mt-3 ml-5"
                disabled={isSame}
                onClick={(e) => openEditRoleModal(e)}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* start modal container */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <h3 className="mb-3" style={{ color: "green" }}>
            Please confirm, you want to update role?
          </h3>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button className="pds-button pds-button-primary" onClick={updateRole}>
              Yes
            </button>
            <button className="pds-button pds-button-primary cancel" onClick={cancelModal}>
              No
            </button>
          </div>
        </Modal>
      </div>
      {/* end modal container */}
    </div>
  );
};

export default EditRole;
