import React, { useEffect, useState, useContext } from "react";
import "./ProcessMessage.css";
import "../../App.css";
import "@pds-react/icon";
import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Icon from "@pds-react/icon";
import Modal from "react-modal";
import Config from "../../config/index";
import { AppContext } from "../../Context/context";
import Loading from "../Loading/Loading";
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "56px 44px 48px 32px",
    width: "468px",
    textAlign: "center",
  },
};

const ProcessMessage = () => {
  const { accounts } = useMsal();
  const email = accounts[0] && accounts[0].idTokenClaims.email;
  const location = useLocation();
  const closedError = location?.state?.closedErrorForProcess;
  const newError = location?.state?.errorForProcess;
  const [selectedErrorData, setSelectedErrorData] = useState([]);
  const [actionValue, setActionValue] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const referenceData = useContext(AppContext);
  const token = referenceData.dataDomainAccessToken;
  const canSubmit = !(commentValue.length > 0);

  const actionData = [{ action: "New" }, { action: "In progress" }, { action: "Resubmit" }, { action: "Close" }];

  useEffect(() => {
    if (newError) {
      const selectedFilter = actionData.filter((item) => item.action !== newError[0]?.action);
      setSelectedErrorData(selectedFilter);
      setActionValue(selectedFilter[0].action);
    } else {
      const selectedFilter = actionData.filter((item) => item.action !== "Close" && item.action !== "Resubmit");
      setSelectedErrorData(selectedFilter);
      setActionValue(selectedFilter[0].action);
    }
  }, [newError, closedError]);

  let navigate = useNavigate();
  const routeChange = () => {
    navigate("/Message");
  };
  const handleChange = (e) => {
    setActionValue(e.target.value);
  };
  const handleTextChange = (e) => {
    setCommentValue(e.target.value);
  };
  const openProcessModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const getDataSet = async (params) => {
    const response = await fetch(`${Config.pedrApiUrl.ERROR_API_URL}&${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }
    return res;
  };

  const updateApiCall = async (updatedErrorPayload) => {
    let apiRequestArray = [];
    let updateReqBody = [];

    // create array of all api calls
    for (let i = 0; i < updatedErrorPayload.length; i++) {
      const element = updatedErrorPayload[i];
      apiRequestArray.push(getDataSet(`SK=${encodeURIComponent(element.SK)}`));
    }

    // start all api calls in parallel using promise all
    let response = await Promise.all(apiRequestArray);
    // update action value for each record and push it into list
    for (let i = 0; i < response.length; i++) {
      const element = response[i];
      element.data[0].action = actionValue;
      element.data[0].comment = commentValue;
      element.data[0].userName = email;
      updateReqBody.push(element.data[0]);
    }

    // start update api call
    const result = await fetch(`${Config.pedrApiUrl.ERROR_API_URL}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateReqBody),
    });
    if (result.status !== 200) {
      throw new Error("Error in update api call.");
    }
    return;
  };

  const saveProcess = async () => {
    var newErrorUpdated = newError?.map((m) => ({
      ...m,
      action: actionValue,
      comment: commentValue,
      userName: email,
    }));

    var closedErrorUpdated = closedError?.map((close) => ({
      ...close,
      action: actionValue,
      comment: commentValue,
      userName: email,
    }));

    const updatedErrorPayload = closedErrorUpdated ? closedErrorUpdated : newErrorUpdated;
    await updateApiCall(updatedErrorPayload).catch((res) => console.log(res));
  };

  function processMessage() {
    setIsOpen(false);
    setIsLoading(true);
    saveProcess().then(() => {
      setIsLoading(false);
      routeChange();
    });
  }

  function cancelModal() {
    setIsOpen(false);
  }

  return (
    <>
      {!isLoading ? (
        <>
          <div className="container-fluid">
            <h3 className="mb-3">Process Error Messages</h3>
            <div className="row" style={{ margin: "0" }}>
              <div className="col-md-12">
                <form style={{ width: "100%" }}>
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <label htmlFor="status">
                      Status
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        *
                      </span>
                      :
                    </label>
                    <select
                      autoFocus="autofocus"
                      id="action"
                      name="action"
                      value={actionValue}
                      onChange={(e) => handleChange(e)}
                    >
                      {selectedErrorData?.map((item, index) => {
                        return (
                          <option key={index} value={item?.action}>
                            {item?.action}
                          </option>
                        );
                      })}
                    </select>
                    <span className="down-arrow-process">
                      <Icon name="chevron-down" size={24} />
                    </span>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "10px 0px",
                    }}
                  >
                    <label htmlFor="comments">
                      Comments
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        *
                      </span>
                      :
                    </label>
                    <textarea
                      className="form-control"
                      id="comments"
                      value={commentValue}
                      onChange={(e) => handleTextChange(e)}
                      style={{
                        width: "100%",
                        height: " 60px",
                        border: "1px solid gray",
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={canSubmit}
                    onClick={(e) => openProcessModal(e)}
                    className="pds-button pds-button-primary"
                    style={{ margin: "20px 0px 20px 150px" }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div>
              <h4 style={{ backgroundColor: "#f6f6f8", padding: "10px" }}>Selected Records:</h4>
              <div className="table-responsive">
                <table data-responsive-table="true" className="table">
                  <thead>
                    <tr>
                      <td>
                        <b>Error ID</b>
                      </td>
                      <td>
                        <b>Message ID</b>
                      </td>
                      <td>
                        <b>Error</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {newError?.length > 0
                      ? newError?.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item?.id}</td>
                              <td>{item?.messageId}</td>
                              <td>{item?.error}</td>
                            </tr>
                          );
                        })
                      : null}

                    {closedError?.length > 0
                      ? closedError?.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item?.id}</td>
                              <td>{item?.messageId}</td>
                              <td>{item?.error}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={processMessage}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <h3 className="mb-3" style={{ color: "green" }}>
                  Please confirm, if you want to process data?
                </h3>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <button className="pds-button pds-button-primary" onClick={processMessage}>
                    Yes
                  </button>
                  <button className="pds-button pds-button-primary cancel" onClick={cancelModal}>
                    No
                  </button>
                </div>
              </Modal>
            </div>
            {/* end modal container */}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ProcessMessage;
