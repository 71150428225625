import React from "react";
import ErrorPages from "@pds-react/errorPages";
import Button from "@pds-react/button";
import { useNavigate } from "react-router-dom";

function NotFoundError() {
  const navigate = useNavigate();
  return (
    <div className="container" data-testid="NotFoundError">
      <div className="text-center panel panel-primary" style={{ borderRadius: "0px 0px 10px 10px", height: "100%" }}>
        <ErrorPages
          body="We've looked everywhere and can't seem to find the page you're looking for."
          title="We're sorry."
          variant="notFound"
        />
        <Button
          className={"util-margin-bottom-sm-15 util-margin-bottom-xs-15 util-margin-top-sm-15 util-margin-top-xs-15"}
          onClick={() => {
            navigate("/");
          }}
        >
          {"Head back to the home page"}
        </Button>
      </div>
    </div>
  );
}
NotFoundError.propTypes = {};
export default NotFoundError;
