import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateRole.css";
import Modal from "react-modal";
import { AppContext } from "../../Context/context";
import Config from "../../config/index";
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "56px 44px 48px 32px",
    width: "468px",
    textAlign: "center",
  },
};
const CreateRole = () => {
  const referenceData = useContext(AppContext);
  const systemList = referenceData.systemList;
  const subjectList = referenceData.subjectList;
  const categoryList = referenceData.categoryList;
  const token = referenceData.dataDomainAccessToken;
  const [adGroupData, setAdGroupData] = useState("");
  const [dataSubjectData, setDataSubjectData] = useState("");
  const [roleData, setRoleData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [adGroupValidation, setAdGroupValidation] = useState("");
  const [roleValidation, setRoleValidation] = useState("");
  const [dataSubjectValidation, setDataSubjectValidation] = useState("");
  const [categoryValidation, setCategoryValidation] = useState("");

  useEffect(() => {
    checkIfIsValid();
  }, [roleData, dataSubjectData, categoryData]);

  let navigate = useNavigate();
  const goToList = () => {
    let path = "/Role";
    navigate(path);
  };

  const handleAdGroupChange = (e) => {
    setChangedValues(e.target.value, "adGroup");
  };
  const handleRoleChange = (e) => {
    setChangedValues(e.target.value, "role");
    checkIfIsValid();
  };
  const handleDataSubjectChange = (e) => {
    setChangedValues(e.target.value, "dataSubject");
    checkIfIsValid();
  };
  const handleCategoryChange = (e) => {
    setChangedValues(e.target.value, "category");
    checkIfIsValid();
  };

  const sortedRoleList = systemList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedSubjectList = subjectList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedCategoryList = categoryList.sort((a, b) => a.Name.localeCompare(b.Name));

  const openCreateRoleModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const getRefDataAsObject = (item) => {
    return {
      ...item,
      dataSubject: getRefObject(item.dataSubject, subjectList, "dataSubject"),
      categoryId: getRefObject(item.categoryId, categoryList, "categoryId"),
      role: getRefObject(item.role, systemList, "role"),
    };
  };

  const getRefObject = (item, refData, type) => {
    if (refData) {
      let foundItem = null;
      let findVal = null;

      if (!type.includes("role")) {
        foundItem = refData.find((i) => Number(i.Id) === Number(item));
        findVal = foundItem ? { id: parseInt(foundItem.Id), name: foundItem.Name } : item;
        return findVal;
      } else {
        foundItem = refData.find((i) => String(i.Name) === String(item.replace(" - View", "")));
        findVal = foundItem ? { id: parseInt(foundItem.Id), name: foundItem.Name } : item;
        return findVal;
      }
    } else {
      return item;
    }
  };

  const updatePayload = (data) => {
    const result = getRefDataAsObject(data);
    return result;
  };

  const getPayload = () => {
    const data = {
      adGroup: adGroupData,
      dataSubject: dataSubjectData,
      role: roleData,
      categoryId: categoryData,
    };
    const newData = updatePayload(data);
    return newData;
  };

  const createData = async () => {
    validateData();
    try {
      if (isValid) {
        const payload = getPayload();
        const response = await fetch(`${Config.pedrApiUrl.ROLE_API_URL}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
        if (response.status !== 200) {
          throw new Error(`${response.status}`);
        }
        resetValidationState();
      }
    } catch (e) {
      navigate("/error", { state: { e } });
    }
  };

  const validateData = () => {
    const adGroupValidationMsg = adGroupData == "" ? "The AD Group is required field." : "";
    setAdGroupValidation(adGroupValidationMsg);

    const roleValidationMsg = roleData == "" ? "The Role field is required." : "";
    setRoleValidation(roleValidationMsg);

    const dataSubjectValidationMsg = dataSubjectData == "" ? "The Data Subject field is required." : "";
    setDataSubjectValidation(dataSubjectValidationMsg);

    const categoryValidationMsg = categoryData == "" ? "The Error Category field is required." : "";
    setCategoryValidation(categoryValidationMsg);
  };

  const resetValidationState = () => {
    setIsValid(false);
    setAdGroupValidation("");
    setRoleValidation("");
    setDataSubjectValidation("");
    setCategoryValidation("");
  };

  const checkIfIsValid = () => {
    const ifValid = adGroupData != "" && roleData != "" && dataSubjectData != "" && categoryData != "" ? true : false;
    setIsValid(ifValid);
  };

  const setChangedValues = (value, type) => {
    if (type == "adGroup") {
      setAdGroupData(value);
    } else if (type == "role") {
      setRoleData(value);
    } else if (type == "dataSubject") {
      setDataSubjectData(value);
    } else {
      setCategoryData(value);
    }
  };

  function closeModal() {
    setIsOpen(false);
    goToList();
  }

  async function createRole() {
    setIsOpen(false);
    await createData();
    if (isValid) {
      goToList();
    }
  }

  function cancelModal() {
    setIsOpen(false);
  }

  return (
    <div className="container-fluid">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3 className="mb-3">Create</h3>
          <h5>Create Role</h5>
        </div>
        <div>
          <button className="pds-button pds-button-primary mt-3" onClick={goToList}>
            Back to List
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <form style={{ width: "50%", margin: "0 auto" }}>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label htmlFor="adGroup" className="mb-3">
                AD Group
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Please enter AD Group"
                id="adGroup"
                onChange={(e) => handleAdGroupChange(e)}
              />
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {adGroupValidation}
              </p>
            )}
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="role">
                System/Role
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select className="form-control" id="role" name="role" onChange={(e) => handleRoleChange(e)}>
                <option value="" selected="selected">
                  Please select role
                </option>
                {sortedRoleList.map((role) => (
                  <option key={role.Id} value={role.Name}>
                    {role.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {roleValidation}
              </p>
            )}
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="dataSubject">
                Data Subject
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select
                className="form-control"
                id="dataSubject"
                name="dataSubject"
                onChange={(e) => handleDataSubjectChange(e)}
              >
                <option value="">Please select a data subject</option>
                {sortedSubjectList.map((subject) => (
                  <option key={subject.Id} value={subject.Id}>
                    {subject.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {dataSubjectValidation}
              </p>
            )}
            <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
              <label htmlFor="category">
                Error Category
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </span>
                :
              </label>
              <select className="form-control" id="category" name="category" onChange={(e) => handleCategoryChange(e)}>
                <option value="">Please select a category</option>
                {sortedCategoryList.map((category) => (
                  <option key={category.Id} value={category.Id}>
                    {category.Name}
                  </option>
                ))}
              </select>
            </div>
            {!isValid && (
              <p
                style={{
                  color: "red",
                  marginLeft: "158px",
                  marginBottom: "10px",
                }}
              >
                {categoryValidation}
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                className="pds-button pds-button-primary mt-3 ml-5"
                onClick={(e) => openCreateRoleModal(e)}
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* start modal container */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <h3 className="mb-3" style={{ color: "green" }}>
            Please confirm, you want to create a new role?
          </h3>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button className="pds-button pds-button-primary" onClick={createRole}>
              Yes
            </button>
            <button className="pds-button pds-button-primary cancel" onClick={cancelModal}>
              No
            </button>
          </div>
        </Modal>
      </div>
      {/* end modal container */}
    </div>
  );
};

export default CreateRole;
