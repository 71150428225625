import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Config from "../config/index";
import { useMsal, useAccount } from "@azure/msal-react";
import { useNavigate } from "react-router";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [systemList, setSystemList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [dataDomainAccessToken, setDataDomainAccessToken] = useState("");
  const [tokenExpiry, setTokenExpiry] = useState();
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  let navigate = useNavigate();

  const setSystemData = async (token) => {
    const api_type = "system";
    const response = await fetch(`${Config.refApiUrl.REF_BASE_URL}?api_type=${api_type}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status}`);
    } else {
      const extendedSystemList = [...res, { Id: 9000, Name: "Admin" }, { Id: 9001, Name: "Main Application" }];
      setSystemList(extendedSystemList);
    }
  };

  const setSubjectData = async (token) => {
    const api_type = "subject";
    const response = await fetch(`${Config.refApiUrl.REF_BASE_URL}?api_type=${api_type}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status}`);
    } else {
      setSubjectList(res);
    }
  };

  const setCategoryData = async (token) => {
    const api_type = "category";
    const response = await fetch(`${Config.refApiUrl.REF_BASE_URL}?api_type=${api_type}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status}`);
    } else {
      setCategoryList(res);
    }
  };

  const setReferenceData = async () => {
    try {
      await Promise.all([
        setSystemData(dataDomainAccessToken),
        setSubjectData(dataDomainAccessToken),
        setCategoryData(dataDomainAccessToken),
      ]);
    } catch (e) {
      navigate("/error", { state: { e } });
    }
  };

  const requestDataDomainAccessToken = async () => {
    if (account) {
      const accessTokenRequest = {
        ...Config.loginRequest,
        account: account,
      };
      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        setTokenExpiry(response.expiresOn);
        if (response !== null || response !== undefined) {
          setDataDomainAccessToken(response.accessToken);
        } else {
          throw new Error(`${response.status}`);
        }
      } catch (e) {
        navigate("/error", { state: { e } });
      }
    }
  };

  useEffect(() => {
    if (dataDomainAccessToken == "" || tokenExpiry < new Date()) {
      requestDataDomainAccessToken();
    }
  }, [account, instance]);

  useEffect(() => {
    if (dataDomainAccessToken) {
      setReferenceData();
    }
  }, [dataDomainAccessToken]);

  return (
    <AppContext.Provider
      value={{
        systemList,
        subjectList,
        categoryList,
        dataDomainAccessToken,
        requestDataDomainAccessToken,
        tokenExpiry,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export { AppProvider, AppContext };

AppProvider.propTypes = {
  children: PropTypes.any,
};
