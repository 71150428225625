import React from "react";
import ErrorPages from "@pds-react/errorPages";
import Button from "@pds-react/button";

function UnauthorizedError() {
  return (
    <div className="container" data-testid="UnauthorizedError">
      <div className="text-center panel panel-primary" style={{ borderRadius: "0px 0px 10px 10px", height: "100%" }}>
        <ErrorPages
          body="You do not have access to use this application."
          title="We're sorry."
          variant="unauthenticated"
        />
        <Button
          className={"util-margin-bottom-sm-15 util-margin-bottom-xs-15 util-margin-top-sm-15 util-margin-top-xs-15"}
          onClick={() => {
            window.location.assign("https://servicemgmt.principal.com/CherwellPortal/ServiceDeskOnline?_=3bd096b6#0");
          }}
        >
          {"Please contact support"}
        </Button>
      </div>
    </div>
  );
}
UnauthorizedError.propTypes = {};
export default UnauthorizedError;
