import React from "react";
import { NavLink } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import SignInButton from "../Auth/SignInButton";
import SignOutButton from "../Auth/SignOutButton";
import "./Header.css";

const Header = () => {
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  return (
    <nav className="pds-navigationPrimary pds-navigationPrimary-primary pds-enterpriseHeader">
      <div className="pds-navigationPrimary-container" style={{ padding: "0px 15px" }}>
        <div className="pds-navigationPrimary-logo">
          <a
            data-gtm="navigation-primary"
            className="pds-navigationPrimary-logo-link pds-link-unstyled"
            href="#"
            aria-label="Principal Homepage"
          >
            <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
          </a>
        </div>
        <AuthenticatedTemplate>
          <div className="pds-navigationPrimary-menu-container">
            <ul className="pds-navigationPrimary-tier-one">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/Message">Error Messages</NavLink>
              </li>
              <li>
                <NavLink to="/Closed">Closed</NavLink>
              </li>
              <li>
                <NavLink to="/Audit">Audit</NavLink>
              </li>
              <li>
                <NavLink to="/Role">Role</NavLink>
              </li>
              <li>
                <NavLink to="/Contact">Contact</NavLink>
              </li>

              <li className="pds-navigationPrimary-menu-pull-right">
                <a className="pds-link-unstyled pds-navigationPrimary-dropdown-link">{name}</a>
              </li>
              <li>
                <SignOutButton variant={"primary-light"} />
              </li>
            </ul>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="pds-navigationPrimary-menu-container">
            <ul className="pds-navigationPrimary-tier-one">
              <li className="pds-navigationPrimary-menu-pull-right">
                <SignInButton variant={"primary-light"} />
              </li>
            </ul>
          </div>
        </UnauthenticatedTemplate>
      </div>
    </nav>
  );
};

export default Header;
