import { LogLevel } from "@azure/msal-browser";

// This is Primary Region Config

const pedrBaseURL = "https://api.datadomain.am.staging.principal.com/Error-Dashboard/ErrorDashboard/";

const config = {
  auth: {
    // This is in development phase
    clientId: "c73d31b9-2d2c-48c6-b9b3-de5b262e2fe3",
    authority: "https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430",
    redirectUri: "https://new.errordashboard.am.staging.principal.com",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
  pedrApiUrl: {
    ERROR_API_URL: pedrBaseURL + "Error?PK=Error",
    ACTION_API_URL: pedrBaseURL + "Action",
    AUDIT_API_URL: pedrBaseURL + "Audit?PK=Audit",
    CLOSED_API_URL: pedrBaseURL + "Closed?PK=Error",
    ROLE_API_URL: pedrBaseURL + "Role",
    TAG_API_URL: pedrBaseURL + "Tag",
  },
  refApiUrl: {
    REF_BASE_URL: pedrBaseURL + "Reference",
  },
  loginRequest: {
    scopes: ["api://data-domain-api-staging/.default"],
  },
  graphApiScopeRequest: {
    scopes: ["User.Read"],
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphGroupEndpoint: "https://graph.microsoft.com/v1.0/users/email/memberOf?$top=999",
  },
};

export default config;
