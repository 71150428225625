import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css";
import { useMsal } from "@azure/msal-react";
import Config from "../../config/index";
// import {
//   useReactTable,
//   getCoreRowModel,
//   getSortedRowModel,
//   getPaginationRowModel,
//   getFilteredRowModel,
//   getExpandedRowModel,
//   flexRender,
// } from "@tanstack/react-table";
// import Loading from "../Loading/Loading";
import { AppContext } from "../../Context/context";

export const TagMultipleErrors = () => {
  const location = useLocation();
  const errorList = location?.state?.errorForProcess;
  const { accounts } = useMsal();
  const email = accounts[0] && accounts[0].idTokenClaims.email;
  //   const [data, setApiData] = useState([]);
  const [tagValue, setTagValue] = useState("");
  //   const [expanded, setExpanded] = React.useState({});
  //   const [sorting, setSorting] = React.useState([]);
  //   const [isLoading, setIsLoading] = useState(true);
  //   const [tagLoad, setTagLoad] = useState(0);
  let navigate = useNavigate();

  const referenceData = useContext(AppContext);
  const token = referenceData.dataDomainAccessToken;

  const canClickSave = !(tagValue !== null && tagValue.trim() !== "");

  function handleChange(evt) {
    setTagValue(evt.target.value);
  }

  const getDataSet = async (params) => {
    const response = await fetch(`${Config.pedrApiUrl.ERROR_API_URL}&${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }
    return res;
  };

  //   const getLocalTime = (e) => {
  //     const dateObject = new Date(e + "Z");
  //     return dateObject
  //       .toLocaleString(undefined, {
  //         year: "numeric",
  //         month: "2-digit",
  //         day: "2-digit",
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         hour12: true,
  //       })
  //       .replace(",", "");
  //   };

  //   const updateData = (data) => {
  //     return data.map((item) => {
  //       return {
  //         ...item,
  //         insertedDate: getLocalTime(item.insertedDate),
  //       };
  //     });
  //   };

  //   const getTableData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await fetch(`${Config.pedrApiUrl.TAG_API_URL}?id=${"errorId"}`, {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       const res = await response.json();
  //       if (response.status !== 200) {
  //         throw new Error();
  //       }
  //       const mappedData = updateData(res.data);
  //       const sortedMappedData = mappedData.sort((a, b) => new Date(b.insertedDate) - new Date(a.insertedDate));
  //       setApiData(sortedMappedData);
  //       setIsLoading(false);
  //     } catch (e) {
  //       console.log(e);
  //       navigate("/error", { state: { e } });
  //     }
  //   };

  const getPayload = () => {
    let payloadList = [];
    errorList?.forEach((item) => {
      payloadList.push({
        id: item.id,
        tag: tagValue,
        userName: email,
      });
    });
    return payloadList;
  };

  const goToErrorMessagePage = () => {
    navigate("/Message");
  };

  const saveTagData = async () => {
    if (tagValue.trim() !== "" || tagValue !== null) {
      const payload = getPayload();
      const response = await fetch(`${Config.pedrApiUrl.TAG_API_URL}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status !== 200) {
        throw new Error(`${response.status}`);
      }
      setTagValue("");
      updateTagForError(tagValue);
      //   setTagLoad((c) => c + 1);
    } else {
      console.error("Please enter valid tag!");
    }
  };

  const updateTagForError = async (tagValue) => {
    let apiRequestArray = [];
    let updateReqBody = [];

    // create array of all api calls
    for (let i = 0; i < errorList.length; i++) {
      const element = errorList[i];
      apiRequestArray.push(getDataSet(`SK=${encodeURIComponent(element.SK)}`));
    }

    // start all api calls in parallel using promise all
    let errorApiResponse = await Promise.all(apiRequestArray);
    // update tag value for each record and push it into list
    for (let i = 0; i < errorApiResponse.length; i++) {
      const element = errorApiResponse[i];
      element.data[0].tag = tagValue;
      element.data[0].userName = email;
      updateReqBody.push(element.data[0]);
    }

    const response = await fetch(`${Config.pedrApiUrl.ERROR_API_URL}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateReqBody),
    });

    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }
    goToErrorMessagePage();
  };

  //   const columns = React.useMemo(
  //     () => [
  //       {
  //         accessorKey: "userName",
  //         header: () => <span>Username</span>,
  //       },

  //       {
  //         accessorKey: "tag",
  //         header: () => <span>Tagging</span>,
  //       },
  //       {
  //         accessorKey: "insertedDate",
  //         header: () => <span>Date</span>,
  //       },
  //     ],
  //     []
  //   );

  //   const table = useReactTable({
  //     data,
  //     columns,
  //     state: {
  //       sorting,
  //       expanded,
  //     },
  //     onSortingChange: setSorting,
  //     onExpandedChange: setExpanded,
  //     getSubRows: (row) => row.subRows,
  //     getCoreRowModel: getCoreRowModel(),
  //     getSortedRowModel: getSortedRowModel(),
  //     getPaginationRowModel: getPaginationRowModel(),
  //     getFilteredRowModel: getFilteredRowModel(),
  //     getExpandedRowModel: getExpandedRowModel(),
  //     debugTable: true,
  //   });

  //   useEffect(() => {
  //     getTableData().catch((e) => console.log(e));
  //   }, [tagLoad]);

  return (
    <div className="container-fluid">
      <h4>Tagging Details</h4>
      {/* {!isLoading ? (
        <div className="pds-table-scrolling pds-locked-header-row" style={{ height: "200px", marginTop: "20px" }}>
          <table className="pds-table-container pds-table pds-table-bordered pds-table-striped-odd">
            <thead className="pds-table pds-table-bordered" style={{ backgroundColor: "#e6e6e6", color: "black" }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th className="sort pds-table pds-table-bordered" key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <>
                    <tr
                      key={row.id}
                      onClick={() => {
                        row.toggleExpanded();
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <>
                            {cell.row.original.tag ? (
                              <td className="pds-dataTable-table-item" key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ) : null}
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "15px",
          marginTop: "15px",
        }}
      >
        <div style={{ width: "100%" }}>
          <input id="inputBasic" type="text" name="tags" placeholder="Tags" value={tagValue} onChange={handleChange} />
        </div>
        <button
          className="save-btn pds-button pds-button-primary mr-2 ml-2"
          disabled={canClickSave}
          onClick={saveTagData}
        >
          Save
        </button>
        <button className="cancel-btn pds-button pds-button-secondary" onClick={goToErrorMessagePage}>
          Cancel
        </button>
      </div>
      <>
        <h4 style={{ backgroundColor: "#f6f6f8", padding: "10px" }}>Selected Records:</h4>
        <div className="table-responsive">
          <table data-responsive-table="true" className="table">
            <thead>
              <tr>
                <td>
                  <b>Error ID</b>
                </td>
                <td>
                  <b>Message ID</b>
                </td>
                <td>
                  <b>Error</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {errorList?.length > 0
                ? errorList?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item?.id}</td>
                        <td>{item?.messageId}</td>
                        <td>{item?.error}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};
