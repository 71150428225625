import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";

const Home = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="container-fluid">
          <h2>The following items are still in progress</h2>
          <br></br>
          <ol style={{ marginLeft: "40px" }}>
            <li>
              <h4 style={{ paddingBottom: "10px" }}>Metrics</h4>
              <p>Once the metrics which should be captured are defined, we can start working on that</p>
            </li>
          </ol>
        </div>
      </AuthenticatedTemplate>
    </>
  );
};

export default Home;
