import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <div style={{ padding: "12px 0" }}>
        <a
          href="https://www.principal.com/terms-of-use"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Terms of Use</b>
        </a>
        <span style={{ color: "#fff" }}>|</span>
        <a
          href="https://www.principal.com/products-services-disclosures"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Discloures</b>
        </a>
        <span style={{ color: "#fff" }}>|</span>
        <a
          href="https://www.principal.com/privacy-policies"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Privacy</b>
        </a>
        <span style={{ color: "#fff" }}>|</span>
        <a
          href="https://www.principal.com/security-policies"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Security</b>
        </a>
        <span style={{ color: "#fff" }}>|</span>
        <a
          href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Report Fraud</b>
        </a>
        <span style={{ color: "#fff" }}>|</span>
        <a
          href="#placeholder"
          style={{
            color: "#fff",
            textDecoration: "None",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <b>Site Map</b>
        </a>
      </div>
      <div style={{ color: "#fff" }}>&#169; 2016, Principal Global Services, Inc.</div>
      <div style={{ color: "#fff", paddingBottom: "15px" }}>
        Securities offered through Princor Financial Services Corporation, member{" "}
        <b>
          <a href="https://www.sipc.org" style={{ color: "#fff", textDecoration: "None" }}>
            SIPC
          </a>
        </b>
      </div>
    </div>
  );
};

export default Footer;
