import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@pds-react/core/dist/global.min.css";
import "@pds-react/core/dist/icon.min.css";
import "@pds-react/core/dist/color.min.css";
import "@pds-react/core/dist/util.min.css";
import "@pds-react/checkbox/dist/checkbox.min.css";
import "@pds/enterpriseHeader/enterpriseHeader.min";
import "@pds/enterpriseHeader/enterpriseHeader.min.css";
import "@pds/button/button.min";
import "@pds/button/button.min.css";
import "@pds/select/select.min";
import "@pds/select/select.min.css";
import "@pds/input/input.min";
import "@pds/input/input.min.css";
import "@pds/checkbox/checkbox.min";
import "@pds/checkbox/checkbox.min.css";
import "@pds/pagination/pagination.min";
import "@pds/pagination/pagination.min.css";
import "@pds/table/table.min";
import "@pds/table/table.min.css";
import "@pds/pagination/pagination.min";
import "@pds/pagination/pagination.min.css";
import "@pds/pagination/pagination.twig";
import "@pds-react/grid/dist/grid.min.css";
import "@pds-react/errorPages/dist/unauthorizedError.min.css";
import "@pds-react/errorPages/dist/maintenanceError.min.css";
import "@pds-react/errorPages/dist/serverError.min.css";
import "@pds-react/errorPages/dist/notFoundError.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import config from "./config";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "././Context/context";

const msalInstance = new PublicClientApplication(config);

const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AppProvider>
          <App />
        </AppProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
reportWebVitals();
