import React, { useState } from "react";
import PropTypes from "prop-types";

const CopyToClipBoard = ({ ...props }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const exportToJson = () => {
    const tableId = props.type.toLowerCase() === "audit" ? "audit-error-table" : "open-error-table";
    const elTable = document.querySelector(`#${tableId}`);
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 2000);
  };

  return (
    <>
      <button className="pds-button pds-button-secondary mr-2" onClick={exportToJson}>
        COPY
      </button>
      {isAlertVisible && (
        <div className="alert-container">
          <div className="alert-inner">
            <h3>Copy to Clipboard! Paste it in a file</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default CopyToClipBoard;

CopyToClipBoard.propTypes = {
  type: PropTypes.any,
};
