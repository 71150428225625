import React from "react";
import { useMsal } from "@azure/msal-react";
import Config from "../../config/index";
import Button from "@pds-react/button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const SignInButton = ({ variant }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(Config.loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(Config.loginRequest).catch((e) => {
        console.log(e);
      });
      navigate("/");
    }
  };
  return (
    <>
      <Button variant={variant} onClick={() => handleLogin("redirect")}>
        Sign in
      </Button>
    </>
  );
};

SignInButton.propTypes = {
  variant: PropTypes.string,
};

export default SignInButton;
