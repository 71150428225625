import React from "react";
import SignInButton from "../Auth/SignInButton";

export const Login = () => {
  return (
    <>
      <br />
      <br />
      <div style={{ textAlign: "center", position: "fixed", top: "40%", width: "100%" }}>
        <SignInButton />
      </div>
      <br />
      <br />
    </>
  );
};
