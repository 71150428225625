import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@pds-react/button";
import PropTypes from "prop-types";

const SignOutButton = ({ variant }) => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <Button variant={variant} onClick={() => handleLogout("redirect")}>
      Sign out
    </Button>
  );
};

SignOutButton.propTypes = {
  variant: PropTypes.string,
};
export default SignOutButton;
