import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPageLayout from "./MainPageLayout";
import ProtectedRoutes from "./ProtectedRoutes";
import UnauthorizedError from "../ErrorPages/UnauthorizedError/UnauthorizedError";
import NotFoundError from "../ErrorPages/NotFoundError/NotFoundError";
import ServerError from "../ErrorPages/ServerError/ServerError";
import Message from "../Message/Message";
import Role from "../Role/Role";
import CreateRole from "../Role/CreateRole";
import DetailsRole from "../Role/DetailsRole";
import DeleteRole from "../Role/DeleteRole";
import EditRole from "../Role/EditRole";
import ProcessMessage from "../Message/ProcessMessage";
import Contact from "../Contact/Contact";
import Audit from "../Audit/Audit";
import { TagPopup } from "../Tagging/TagPopup";
import Home from "../Home/Home";
import Closed from "../Closed/Closed";
import { Login } from "../Login/Login";
import { TagMultipleErrors } from "../Tagging/TagMultipleErrors";

function AppRouter() {
  return (
    <Routes>
      <Route element={<MainPageLayout />}>
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/Message" element={<Message />} />
          <Route path="/Role" element={<Role />} />
          <Route path="/CreateRole" element={<CreateRole />} />
          <Route path="/DetailsRole" element={<DetailsRole />} />
          <Route path="/DeleteRole" element={<DeleteRole />} />
          <Route path="/EditRole" element={<EditRole />} />
          <Route path="/ProcessMessage" element={<ProcessMessage />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Closed" element={<Closed />} />
          <Route path="/Audit" element={<Audit />} />
          <Route path="/TagPopup" element={<TagPopup />} />
          <Route path="/TagMultipleErrors" element={<TagMultipleErrors />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" element={<UnauthorizedError />} />
        <Route path="/not-found" element={<NotFoundError />} />
        <Route path="/error" element={<ServerError />} />
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
}

AppRouter.propTypes = {};

export default AppRouter;
