import React from "react";

const Contact = () => {
  return (
    <div className="container-fluid">
      <div>
        <h4 style={{ paddingBottom: "5px" }}>Support:</h4>
        <div>
          <a
            style={{
              color: "#035fa4",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            href="mailto:DLPGITECHIDRDATAINTEGRATIONPLATFORM@exchange.principal.com"
          >
            DLPGITECHIDRDATAINTEGRATIONPLATFORM@exchange.principal.com
          </a>
        </div>
      </div>
      <br />
      <div>
        <h4 style={{ paddingBottom: "5px" }}>Maintainers:</h4>
        <div>
          <a
            style={{
              color: "#035fa4",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            href="mailto:DLGAMDATAREPOSITORYI@exchange.principal.com"
          >
            DLGAMDATAREPOSITORYI@exchange.principal.com
          </a>
        </div>
      </div>
      <br />
      <div>
        <h4 style={{ paddingBottom: "5px" }}>Data Suppliers:</h4>
        <p style={{ fontWeight: 300 }}>Any team which sends data to this shared infrastructure.</p>
      </div>
    </div>
  );
};

export default Contact;
