import React from "react";
import Icon from "@pds-react/icon";
function Loading() {
  return (
    <div className="loading-container">
      <h2 className="text-center">
        <Icon name="loader" size={40} />
      </h2>
    </div>
  );
}

export default Loading;
