import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const ProtectedRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    if (accounts.length === 0) {
      return <Navigate to="/login" replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoutes;
