import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../Context/context";
import Modal from "react-modal";
import Config from "../../config/index";
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "56px 44px 48px 32px",
    width: "468px",
    textAlign: "center",
  },
};

const DeleteRole = () => {
  const location = useLocation();
  const referenceData = useContext(AppContext);
  const token = referenceData.dataDomainAccessToken;
  let navigate = useNavigate();
  const goToList = () => {
    navigate("/Role");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const deleteId = location.state.id;
  const deleteRole = () => {
    fetch(`${Config.pedrApiUrl.ROLE_API_URL}?id=${deleteId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
  };

  const openProcessRole = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  function processRole() {
    setIsOpen(false);
    deleteRole();
    goToList();
  }

  function cancelModal() {
    setIsOpen(false);
  }

  return (
    <div className="container-fluid">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3 className="mb-3">Delete</h3>
          <h5>Delete Roles</h5>
        </div>
        <div>
          <button className="pds-button pds-button-primary mt-3" onClick={goToList}>
            Back to List
          </button>
        </div>
      </div>
      <table style={{ width: "50%", margin: "1% 0 0.5% 3%" }} className="table">
        <tr>
          <th>Role Id</th>
          <td>{location.state.id}</td>
        </tr>
        <tr>
          <th>System/Role</th>
          <td>{location.state.role}</td>
        </tr>
        <tr>
          <th>AD Group</th>
          <td>{location.state.adGroup}</td>
        </tr>
        <tr>
          <th>Data Subject</th>
          <td>{location.state.dataSubject}</td>
        </tr>
        <tr>
          <th>Error Category</th>
          <td>{location.state.categoryId}</td>
        </tr>
      </table>
      <div>
        <button
          className="pds-button mt-3 mb-3"
          style={{
            marginLeft: "10%",
            color: "#ffffff",
            backgroundColor: "#c00000",
            border: "none",
          }}
          onClick={(e) => openProcessRole(e)}
        >
          Delete
        </button>
      </div>

      <div>
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={processRole}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h3 className="mb-3">Please confirm, if you want to delete the role?</h3>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button className="pds-button pds-button-primary" onClick={processRole}>
              Yes
            </button>
            <button
              className="pds-button"
              style={{
                color: "#ffffff",
                backgroundColor: "#c00000",
                border: "none",
              }}
              onClick={cancelModal}
            >
              No
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteRole;
