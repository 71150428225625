import React, { useEffect, useState } from "react";
import ErrorPages from "@pds-react/errorPages";
import Button from "@pds-react/button";
import { useNavigate, useLocation } from "react-router-dom";

function ServerError() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const status_code = location.state.e.message.split(" ")[0];
  console.log(errorMessage);

  useEffect(() => {
    if (status_code == "400") {
      setErrorMessage("Bad Request!");
    } else if (status_code == "401") {
      setErrorMessage("You are not authenticated!");
    } else if (status_code == "403") {
      setErrorMessage("Unauthorized! You do not have sufficient access to use this application!");
    } else if (status_code == "404") {
      setErrorMessage("Not Found!");
    } else if (status_code == "408") {
      setErrorMessage("Request Timeout!");
    } else if (status_code == "500") {
      setErrorMessage("Internal Server Error!");
    } else if (status_code == "502") {
      setErrorMessage("Bad Gateway!");
    } else if (status_code == "503") {
      setErrorMessage("Service Unavailable!");
    } else if (status_code == "504") {
      setErrorMessage("Gateway Timeout!");
    } else {
      setErrorMessage("Something went wrong, please check logs!");
    }
  }, []);

  return (
    <div className="container" data-testid="ServerError">
      <div className="text-center panel panel-primary" style={{ borderRadius: "0px 0px 10px 10px", height: "100%" }}>
        <ErrorPages
          body={
            <>
              <br></br>
              <span style={{ fontWeight: 800 }}>{errorMessage}</span>
              <br></br>
              <br></br>
              <span>Please submit a ticket: </span>
              <br></br>
              <br></br>
              <a
                className="pds-button pds-button-primary"
                href="/" //This can be filled in with a support link.
                target="_blank"
                rel="noopener noreferrer"
              >
                Submit
              </a>
            </>
          }
          title="We're sorry, but something went wrong!"
          variant="server"
        />
        <Button
          className={"util-margin-bottom-sm-15 util-margin-bottom-xs-15 util-margin-top-sm-15 util-margin-top-xs-15"}
          onClick={() => {
            navigate("/");
          }}
        >
          {"Head back to the home page"}
        </Button>
      </div>
    </div>
  );
}
ServerError.propTypes = {};
export default ServerError;
