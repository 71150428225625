import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import Icon from "@pds-react/icon";
import "./BasicTable.css";
import { TagPopup } from "../Tagging/TagPopup";
import PropTypes from "prop-types";
import "./Message.css";
import "jspdf-autotable";
import Config from "../../config/index";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import Loading from "../Loading/Loading";
import { Col, Row } from "@pds-react/grid";
import { AppContext } from "../../Context/context";
import CopyToClipBoard from "./CopyToClipBoard";
import ExportToPdf from "./ExportToPdf";
import ExportToCsv from "./ExportToCsv";
import "react-datepicker/dist/react-datepicker.css";

const Message = () => {
  const refreshTime = 600;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [validationError, setValidationError] = useState("");
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [originalErrorToBeProcessed, setOriginalErrorToBeProcessed] = useState([]);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [errorForProcess, setErrorForProcess] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [sorting, setSorting] = useState([]);
  const [mappedErrorForTag, setMappedErrorForTag] = useState();
  const [countdown, setCountdown] = useState(refreshTime);
  const [nextDataSet, setNextDataSet] = useState({});
  // const [counter, setCounter] = useState(1);
  // const [disableForward, setDisableForward] = useState(false);
  // const [disableBackward, setDisableBackward] = useState(true);
  // const [searchParams, setSearchParams] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [totalRecCount, setTotalRecCount] = useState(0);
  const [errorLoad, setErrorLoad] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(10);
  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [filterValueObj, setFilterValueObject] = useState({});

  const initialState = [
    {
      PK: "",
      SK: "",
      action: "",
      categoryId: "",
      component: "",
      dataSubject: "",
      destinationAddress: "",
      error: "",
      errorDate: "",
      exception: "",
      exceptionType: "",
      fieldValueType: [""],
      insertedDate: "",
      messageId: "",
      messageSubject: "",
      payloadType: "",
      rawData: "",
      resubmitQueue: "",
      systemId: "",
      tag: "",
      ttl: "",
      userName: "",
    },
  ];

  const [errorDataById, setErrorDataById] = useState(initialState);
  const [canClickSearch, setCanClickSearch] = useState(true);
  let fieldValueCounter = 0;
  const limit = 1000;

  const referenceData = useContext(AppContext);
  const { categoryList, dataDomainAccessToken, requestDataDomainAccessToken, subjectList, systemList, tokenExpiry } =
    referenceData;
  const token = dataDomainAccessToken;
  const tokenRefresh = requestDataDomainAccessToken;
  const tokenExpiryDate = new Date(tokenExpiry);

  const sortedSystemList = systemList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedSubjectList = subjectList.sort((a, b) => a.Name.localeCompare(b.Name));
  const sortedCategoryList = categoryList.sort((a, b) => a.Name.localeCompare(b.Name));

  let navigate = useNavigate();

  useEffect(() => {
    getErrorData().catch((e) => console.log(e));
  }, [errorLoad]);

  useEffect(() => {
    setErrorLoad((c) => c + 1);
  }, [categoryList, dataDomainAccessToken]);

  useEffect(() => {
    if (startDate && endDate) {
      validateDateRange();
      enableButtonClick();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (columnFilters.length > 0) {
      enableButtonClick();
    }
  }, [columnFilters]);

  // start timer code
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    if (countdown === 0) {
      clearInterval(timer);
      setErrorLoad((c) => c + 1);
    }
    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const getErrorData = async () => {
    if (tokenExpiryDate < new Date()) {
      await tokenRefresh();
      return;
    }
    try {
      let cursor;
      let roleIndex;
      setIsLoading(true);
      if (tablePageIndex !== 0) {
        cursor = encodeURIComponent(String(nextDataSet[tablePageIndex].cursor));
        roleIndex = nextDataSet[tablePageIndex].roleIndex;
      }
      if (categoryList.length === 0) {
        return;
      }
      let query_params = buildQueryParams(cursor, roleIndex);
      let apiRes = await getDataSet(query_params);
      updateNextDataSet(apiRes, tablePageIndex);
      const mappedData = updateData(apiRes.data);
      const sortedMappedData = mappedData.sort((a, b) => new Date(b.errorDate) - new Date(a.errorDate));
      const addedFieldsData = addAdditionalFieldValues(sortedMappedData);
      setOriginalErrorToBeProcessed(apiRes.data);
      setOriginalTableData(addedFieldsData);
      let filteredData = await getFilteredTableData(addedFieldsData);
      setApiData(filteredData);
      setTotalRecCount(filteredData.length);
      setCountdown(refreshTime);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      navigate("/error", { state: { e } });
    }
  };

  const buildQueryParams = (cursor = null, roleIndex = null) => {
    let params = [];
    if (startDate && endDate)
      params.push(`startDate=${dateTimeToISOString(startDate)}&endDate=${dateTimeToISOString(endDate)}`);
    // if (searchParams.length > 0) params.push(`${parseParams(searchParams)}`);
    if (cursor && roleIndex) params.push(`cursor=${cursor}&role-chunk-index=${roleIndex}`);
    params.push(`limit=${limit}`);
    const queryParams = params.join("&");
    return queryParams;
  };

  const dateTimeToISOString = (date) => {
    var convertedDate = new Date(date.toISOString());
    convertedDate.setDate(convertedDate.getDate() + parseInt(1));
    const correctDate = convertedDate.toISOString().replace("Z", "");
    return correctDate;
  };

  const search = () => {
    setTablePageIndex(0);
    setErrorLoad((c) => c + 1);
  };

  const getFilteredTableData = async (addedFieldsData) => {
    if (Object.keys(filterValueObj).length) {
      let filteredData = [];
      let selectColTypeList = ["systemId", "dataSubject", "categoryId", "action"];
      originalTableData.forEach((item) => {
        let isFound = true;
        Object.keys(filterValueObj).forEach((key) => {
          if (selectColTypeList.findIndex((selectkey) => selectkey === key) > -1) {
            if (item[key] != filterValueObj[key]) isFound = false;
          } else if (!item[key] || (item[key] && !item[key].toString().includes(filterValueObj[key]))) isFound = false;
        });
        if (isFound) filteredData.push(item);
      });
      return filteredData;
    } else {
      return addedFieldsData;
    }
  };

  const filterTableData = (value, colId) => {
    if (colId && originalTableData) {
      let updatedObj = {};
      updatedObj = { ...filterValueObj };
      if (value) {
        updatedObj[colId] = value;
      } else if (Object.prototype.hasOwnProperty.call(updatedObj, colId)) {
        delete updatedObj[colId];
      }
      if (Object.keys(updatedObj).length) {
        setFilterValueObject(updatedObj);
      } else {
        setFilterValueObject({});
      }
    }
  };

  const updateNextDataSet = (res, objIndex) => {
    const nextCursor = res["next-cursor"];
    const roleChunkIndex = res["role-chunk-index"];
    if (nextCursor) {
      setNextDataSet((prevCursor) => ({
        ...prevCursor,
        [objIndex + 1]: { cursor: nextCursor, roleIndex: `${roleChunkIndex}` },
        1: null,
      }));
      // setDisableForward(false);
    } else {
      // setDisableForward(true);
    }
  };

  const getErrorDataById = async (id) => {
    const response = await getDataSet(id);
    const mappedData = updateData(response.data);
    const addedFields = addAdditionalFieldValues(mappedData);
    return addedFields;
  };

  const updateData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        errorDate: handleDateChange(item.errorDate),
        insertedDate: handleDateChange(item.insertedDate),
        systemId: getLabelName(item.systemId, systemList),
        dataSubject: getLabelName(item.dataSubject, subjectList),
        categoryId: getLabelName(item.categoryId, categoryList),
      };
    });
  };

  const getLabelName = (itemId, valueMapped) => {
    if (valueMapped) {
      const findItem = valueMapped.find((i) => Number(i.Id) === Number(itemId));
      const findVal = findItem ? findItem.Name : itemId;
      return findVal;
    } else {
      return itemId;
    }
  };

  const handleDateChange = (e) => {
    const dateObject = new Date(e + "Z");
    return dateObject
      .toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
  };

  const addAdditionalFieldValues = (sortedMappedData) => {
    return sortedMappedData.map((rowData) => {
      return {
        field1: rowData.fieldValue.length > 0 ? rowData.fieldValue[0] : "",
        field2: rowData.fieldValue.length > 1 ? rowData.fieldValue[1] : "",
        field3: rowData.fieldValue.length > 2 ? rowData.fieldValue[2] : "",
        field4: rowData.fieldValue.length > 3 ? rowData.fieldValue[3] : "",
        ...rowData,
      };
    });
  };

  const getDataSet = async (params) => {
    const response = await fetch(`${Config.pedrApiUrl.ERROR_API_URL}&${params}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (response.status !== 200) {
      throw new Error(`${response.status}`);
    }
    return res;
  };

  const validateDateRange = () => {
    if (startDate && endDate) {
      if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        setValidationError("Start Date & End Date should be valid dates!");
      }
      if (startDate <= endDate) {
        setValidationError("");
      } else {
        setValidationError("End date should be greater than Start date!");
      }
    } else {
      setValidationError("");
    }
  };

  const enableButtonClick = () => {
    if (columnFilters.length > 0 || !validationError) {
      setCanClickSearch(false);
    }
  };

  const resetFilters = () => {
    setFilterValueObject({});
    setColumnFilters([]);
    // setSearchParams([]);
    setStartDate("");
    setEndDate("");
    setValidationError("");
    setErrorLoad((c) => c + 1);
    // setCounter(1);
    setTablePageIndex(0);
    // setDisableForward(false);
    // setDisableBackward(true);
    setCanClickSearch(true);
  };

  // const parseParams = (data) => {
  //   let params = data
  //     ?.map(
  //       (filter) =>
  //         `${filter?.id
  //           .replace("systemId", "system-id")
  //           .replace("dataSubject", "data-subject")
  //           .replace("categoryId", "category-id")}=${filter?.value}`
  //     )
  //     .join("&");
  //   return params;
  // };

  const openModal = (row) => {
    setMappedErrorForTag(row.original);
    setShowModal(true);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleRefresh = () => {
    setErrorLoad((c) => c + 1);
  };
  //end timer code

  const routeChange = () => {
    navigate("/ProcessMessage", { state: { errorForProcess } });
  };

  const goToTagMultipleErrPage = () => {
    navigate("/TagMultipleErrors", { state: { errorForProcess } });
  };

  const columns = useMemo(
    () => [
      {
        id: "checkbox",
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            {""}
          </>
        ),

        cell: ({ row }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                disabled: !row.getCanSelect(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </>
        ),
      },

      {
        accessorKey: "errorDate",
        header: () => <span>Date</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "systemId",
        header: () => <span>System</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "dataSubject",
        header: () => <span>Data Subject</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "categoryId",
        header: () => <span>Category</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "error",
        header: () => <span>Error</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "field1",
        header: () => <span>Field 1</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "field2",
        header: () => <span>Field 2</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "field3",
        header: () => <span>Field 3</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "field4",
        header: () => <span>Field 4</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "action",
        header: () => <span>Status</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "tag",
        header: () => "Tagging",
        cell: ({ row }) => (
          <div>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{row.original.tag}</div>
            <div className="tagging-container">
              <p className="tagging-link" style={{ fontSize: "14px" }} onClick={() => openModal(row, false)}>
                View/Add Tags
              </p>
            </div>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const table = useReactTable({
    data: apiData,
    columns,
    state: {
      sorting,
      expanded,
      rowSelection,
      columnFilters,
      pagination: {
        pageIndex: tablePageIndex,
        pageSize: tablePageSize,
      },
    },
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = useRef(null);
    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate, rest.checked]);

    return (
      <>
        <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />
      </>
    );
  }
  const selectCheckData = table.getSelectedRowModel().flatRows;
  const dataKeys = Object.values(selectCheckData);
  const canClick = dataKeys?.length <= 0;
  const selectRowData = dataKeys.map((item) => {
    return item.original;
  });

  useEffect(() => {
    let selectedErrorList = [];
    for (let i = 0; i < selectRowData?.length; i++) {
      for (let j = 0; j < originalErrorToBeProcessed?.length; j++) {
        if (selectRowData[i]?.id === originalErrorToBeProcessed[j]?.id) {
          selectedErrorList.push(originalErrorToBeProcessed[j]);
        }
      }
    }
    setErrorForProcess(selectedErrorList);
  }, [selectCheckData]);

  return (
    <div className="container-fluid">
      {!isLoading ? (
        <>
          <Row className="pds-util-margin-top-sm-15 pds-util-margin-bottom-sm-15 ">
            <Col sm={3} className="pds-input-small">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ fontWeight: "bold", marginRight: "10px", paddingTop: "10px" }} className="text-right">
                  Start Date:{" "}
                </span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/dd/yyyy hh:mm a"
                  placeholderText="MM/DD/YYYY hh:mm aa"
                  startDate={startDate}
                  endDate={endDate}
                  showTimeInput
                  timeInputLabel="Time:"
                  wrapperClassName="datepicker"
                ></DatePicker>
              </div>
            </Col>
            <Col sm={3} className="pds-input-small">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ fontWeight: "bold", marginRight: "10px", paddingTop: "10px" }} className="text-right">
                  End Date:{" "}
                </span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="MM/dd/yyyy hh:mm a"
                  placeholderText="MM/DD/YYYY hh:mm aa"
                  startDate={startDate}
                  endDate={endDate}
                  showTimeInput
                  timeInputLabel="Time:"
                  wrapperClassName="datepicker"
                ></DatePicker>
              </div>
            </Col>
            <Col sm={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <button
                      className="pds-button pds-button-primary search"
                      disabled={canClickSearch || validationError}
                      onClick={() => search()}
                    >
                      Search
                    </button>
                  </div>
                  <div>
                    <button
                      className="pds-button pds-button-secondary clear"
                      disabled={canClickSearch}
                      onClick={() => resetFilters()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <p style={{ fontWeight: "bold", paddingTop: "5px" }}>
                  Time Until Refresh:{formatTime(countdown)}
                  <span className="refresh-timer" onClick={handleRefresh}>
                    <Icon name="refresh-cw" size={20} />
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="btm-border-lite">
            <Col style={{ marginBottom: "15px" }}>
              <div>
                {validationError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "16px",
                      marginTop: "5px",
                    }}
                  >
                    {validationError}
                  </p>
                )}
              </div>
            </Col>
          </Row>

          <div style={{ paddingTop: "15px" }}>
            <Row>
              <Col sm={4}>
                <h4 style={{ paddingTop: "10px" }}>
                  Page {tablePageIndex + 1} with {totalRecCount} records
                </h4>
              </Col>
              <Col sm={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <button className="pds-button pds-button-primary mr-2" disabled={canClick} onClick={routeChange}>
                    Process
                  </button>
                  <button
                    className="pds-button pds-button-primary mr-2"
                    disabled={canClick}
                    onClick={goToTagMultipleErrPage}
                  >
                    Add new tag
                  </button>
                  <CopyToClipBoard type={"Error"}></CopyToClipBoard>
                  <ExportToCsv data={apiData} type={"Error"}></ExportToCsv>
                  <ExportToPdf data={apiData} type={"Error"}></ExportToPdf>
                </div>
              </Col>
            </Row>
            <div id="render_me">
              <table
                className="pds-table-container pds-table pds-table-bordered pds-table-striped-odd"
                id="open-error-table"
                style={{ tableLayout: "fixed", marginTop: "15px" }}
              >
                <thead className="pds-table pds-table-bordered" style={{ backgroundColor: "#e6e6e6", color: "black" }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            className="sort pds-table pds-table-bordered"
                            key={header.id}
                            colSpan={header.colSpan}
                            style={header.id === "checkbox" ? { width: "4%" } : { width: "8.72%" }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                {...{
                                  className: header.column.getCanSort() ? "cursor-pointer select-none mb-1" : "",
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  desc: " 🔽",
                                  asc: " 🔼",
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                            {header.column.getCanFilter() ? (
                              header.column.id !== "systemId" &&
                              header.column.id !== "dataSubject" &&
                              header.column.id !== "categoryId" &&
                              header.column.id !== "action" ? (
                                <FilterInputText
                                  value={header.column.getFilterValue() ?? ""}
                                  onValueChange={(value) => {
                                    filterTableData(value, header.column.id), header.column.setFilterValue(value);
                                  }}
                                  // column={header.column.id}
                                />
                              ) : (
                                <FilterInputSelect
                                  value={header.column.getFilterValue() ?? ""}
                                  onValueChange={(value) => {
                                    filterTableData(value, header.column.id), header.column.setFilterValue(value);
                                  }}
                                  column={header.column.id}
                                  systemList={sortedSystemList}
                                  dataSubjectList={sortedSubjectList}
                                  categoryList={sortedCategoryList}
                                />
                              )
                            ) : null}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {(table.getRowModel().rows.length > 0 &&
                    table.getRowModel().rows.map((row, index) => {
                      return (
                        <>
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => {
                              fieldValueCounter += 1;
                              return (
                                <td
                                  className="pds-dataTable-table-item"
                                  style={{ wordWrap: "break-word" }}
                                  key={`${cell.id} - ${fieldValueCounter}`}
                                  onClick={async () => {
                                    if (cell.getValue() !== undefined ? cell.getValue().type !== "checkbox" : false) {
                                      const sk = encodeURIComponent(row.original["SK"]);
                                      const res = await getErrorDataById(`SK=${sk}`);
                                      setErrorDataById(res);
                                      row.toggleExpanded();
                                      setExpanded(expanded[row.id] ? { [row.id]: false } : { [row.id]: true });
                                    }
                                  }}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              );
                            })}
                          </tr>
                          {row.getIsExpanded() ? (
                            <tr>
                              <td className="toggle-table-data" colSpan="12">
                                <ul style={{ overflowWrap: "anywhere" }}>
                                  {Object.keys(errorDataById[0])
                                    .sort(function (a, b) {
                                      return a.localeCompare(b);
                                    })
                                    .map((key) => {
                                      if (
                                        key === "fieldDisplayName" ||
                                        key === "fieldValueType" ||
                                        key === "fieldValue"
                                      ) {
                                        const displayValues = errorDataById[0][key]
                                          .map((element) => ` "${element}" `)
                                          .join(",");
                                        return (
                                          <li value={key} key={index}>
                                            <strong>{key}</strong> : [{displayValues}]
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li value={key} key={key}>
                                            <strong>{key}</strong> : {errorDataById[0][key]}
                                          </li>
                                        );
                                      }
                                    })}
                                </ul>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })) || (
                    <tr>
                      <td
                        className="pds-dataTable-table-item"
                        colSpan="12"
                        style={{
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        No Records Found!
                      </td>
                    </tr>
                  )}
                </tbody>
                {/* <tfoot>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <td key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder ? null : (
                              <div>
                                {header.column.getCanFilter() ? (
                                  header.column.id !== "systemId" &&
                                  header.column.id !== "dataSubject" &&
                                  header.column.id !== "categoryId" &&
                                  header.column.id !== "action" ? (
                                    <FilterInputText
                                      value={header.column.getFilterValue() ?? ""}
                                      onChange={(value) => header.column.setFilterValue(value)}
                                      column={header.column.id}
                                    />
                                  ) : (
                                    <FilterInputSelect
                                      value={header.column.getFilterValue() ?? ""}
                                      onChange={(value) => header.column.setFilterValue(value)}
                                      column={header.column.id}
                                      systemList={sortedSystemList}
                                      dataSubjectList={sortedSubjectList}
                                      categoryList={sortedCategoryList}
                                    />
                                  )
                                ) : null}
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tfoot> */}
              </table>
              <div>
                {showModal ? (
                  <TagPopup setErrorLoad={setErrorLoad} setShowModal={setShowModal} mappedError={mappedErrorForTag} />
                ) : null}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              {/* <div>
                <button
                  className="pds-button pds-button-primary"
                  disabled={canClick ? true : false}
                  onClick={routeChange}
                >
                  Process
                </button>

                <button
                  className="pds-button pds-button-secondary clear"
                  disabled={canClickSearch}
                  onClick={() => resetFilters()}
                >
                  Clear Filters
                </button>
              </div> */}
              {/* <div className="h-2" /> */}

              <nav className="pds-pagination" style={{ zIndex: "1" }}>
                <ul>
                  <li className="pds-select pds-dataTable-select-options" style={{ display: "flex" }}>
                    <div
                      style={{
                        padding: "10px 5px",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        fontSize: "16px",
                      }}
                    >
                      Show entries
                    </div>
                    <select
                      style={{
                        width: "41%",
                        height: "40px",
                        paddingLeft: "5px",
                        fontSize: "16px",
                        border: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                        setTablePageSize(Number(e.target.value));
                        setTablePageIndex(0);
                      }}
                    >
                      {[10, 25, 50, 100].map((pageSize) => {
                        return (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        );
                      })}
                    </select>
                    <span className="down-arrow">
                      <Icon name="chevron-down" size={24} />
                    </span>
                  </li>

                  <li className="pds-pagination-item">
                    <button
                      className={`pagination-first-item pds-pagination-link pds-pagination-fly-first ${tablePageIndex === 0 ? "disabled" : ""}`}
                      onClick={() => {
                        // paginationApiCall("backward").catch((ex) => console.log(ex));
                        setTablePageIndex(0);
                      }}
                      disabled={tablePageIndex === 0}
                    >
                      {"<<"}
                    </button>
                  </li>
                  <li className="pds-pagination-item">
                    <button
                      className={`pds-pagination-link ${
                        tablePageIndex <= 0 ? "disabled" : ""
                      } pds-pagination-step-forward`}
                      onClick={() => {
                        setTablePageIndex(table.getState().pagination.pageIndex - 1);
                      }}
                      disabled={tablePageIndex <= 0 ? true : false}
                    >
                      {"<"}
                    </button>
                  </li>
                  <li className="pds-pagination-item">
                    <span className="flex items-center gap-1 pds-pagination-link">
                      <strong>
                        Page {tablePageIndex + 1} of {table.getPageCount()}
                      </strong>
                    </span>
                  </li>
                  <li className="pds-pagination-item">
                    <button
                      className={`pds-pagination-link ${
                        tablePageIndex >= table.getPageCount() ? "disabled" : ""
                      } pds-pagination-step-backward`}
                      onClick={() => {
                        setTablePageIndex(table.getState().pagination.pageIndex + 1);
                      }}
                      disabled={tablePageIndex >= table.getPageCount() - 1 ? true : false}
                    >
                      {">"}
                    </button>
                  </li>
                  <li className="pds-pagination-item">
                    <button
                      className={`pagination-last-item pds-pagination-link pds-pagination-fly-last ${tablePageIndex === table.getPageCount() ? "disabled" : ""}`}
                      onClick={() => {
                        // paginationApiCall("forward").catch((ex) => console.log(ex));
                        setTablePageIndex(table.getPageCount() - 1);
                      }}
                      disabled={tablePageIndex === table.getPageCount() - 1}
                    >
                      {">>"}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

function FilterInputText({ value: initialValue, onValueChange }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  // let isDisabled = false;
  // if (column == "errorDate") {
  //   isDisabled = true;
  // }

  return (
    <div>
      <input
        type="text"
        value={value}
        // disabled={isDisabled}
        onChange={(e) => setValue(e.target.value)}
        className="w-36 border shadow rounded pds-input-small"
        placeholder={`Search...`}
      />
    </div>
  );
}

function FilterInputSelect({ value: initialValue, onValueChange, column, systemList, dataSubjectList, categoryList }) {
  const [value, setValue] = useState(initialValue);
  const handleRefDataChange = (e) => {
    setValue(e.target.value);
  };

  // const setChangedValues = (value, type) => {
  //   if (type == "systemId") {
  //     setValue(value);
  //   } else if (type == "dataSubject") {
  //     setValue(value);
  //   } else if (type == "categoryId") {
  //     setValue(value);
  //   } else if (type == "action") {
  //     setValue(value);
  //   }
  // };

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  let dataList = [];
  if (column == "systemId") {
    dataList = systemList;
  } else if (column == "dataSubject") {
    dataList = dataSubjectList;
  } else if (column == "categoryId") {
    dataList = categoryList;
  } else if (column === "action") {
    dataList = [{ action: "New" }, { action: "In progress" }];
  }
  return (
    <select className="pds-select" style={{ height: "40px" }} value={value} onChange={(e) => handleRefDataChange(e)}>
      <option value="">--- Select ---</option>
      {dataList.map((data) => (
        <option key={data.Id ? data.Id : data.action} value={data.Name ? data.Name : data.action}>
          {data.Name ? data.Name : data.action}
        </option>
      ))}
    </select>
  );
}

export default Message;

Message.propTypes = {
  column: PropTypes.any,
  indeterminate: PropTypes.any,
  className: PropTypes.any,
};

FilterInputText.propTypes = {
  value: PropTypes.any,
  onValueChange: PropTypes.any,
  column: PropTypes.any,
};

FilterInputSelect.propTypes = {
  value: PropTypes.any,
  column: PropTypes.any,
  onValueChange: PropTypes.any,
  systemList: PropTypes.any,
  dataSubjectList: PropTypes.any,
  categoryList: PropTypes.any,
};
